@forward './styles/material';
@forward './styles/global';

@use 'material-icons/iconfont/material-icons.css';

@use '@adnova/jf-ng-components/config' as *;


@layer bootstrap, material;

@layer bootstrap {
  @import "bootstrap/scss/bootstrap";
}